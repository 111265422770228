import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import InputMask from "react-input-mask";
import styles from "./Home.module.scss";

type Numero = {
  numero: number;
  taken: boolean;
  nome?: string;
  telefone?: string;
};

const HomePage = () => {
  const numbers = 60;

  const [numeros, setNumeros] = useState<Numero[]>();
  const [selectedNumber, setSelectedNumber] = useState<number>(0);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [numberTaken, setNumberTaken] = useState<boolean>(false);

  const info = useRef<HTMLDivElement>(null);

  useEffect(() => {
    refresh();
  }, []);

  const scrollToInfo = useCallback(() => {
    info.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [info]);

  useEffect(() => {
    setFormSuccess(false);
  }, [formOpen]);

  useEffect(() => {
    setNumberTaken(
      selectedNumber > 60 ||
        selectedNumber < 1 ||
        !!numeros?.find((item) => item.numero === selectedNumber)?.taken
    );
  }, [selectedNumber]);

  const refresh = useCallback(() => {
    fetch("https://rifa.kelvinmarques.com.br/back-end/get.php")
      .then((res) => res.json())
      .then((json) => {
        return Array.from(Array(numbers).keys()).map((number) => {
          const match = json.find((item: Numero) => item.numero === number + 1);
          return match
            ? { ...match, taken: true }
            : { numero: number + 1, taken: false };
        });
      })
      .then((json) => setNumeros(json));
  }, [info]);

  const selectNumber = useCallback(
    (number: number) => {
      setSelectedNumber(number);
      setFormOpen(true);
    },
    [numeros]
  );

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (sendingForm || numberTaken) {
        return;
      }

      setSendingForm(true);

      const target = event.target as any;

      const fd = new FormData();
      fd.append("numero", target.numero.value);
      fd.append("nome", target.nome.value);
      fd.append("telefone", target.telefone.value);
      fd.append(
        "comprovante",
        (document.querySelector('input[name="comprovante"]') as any)?.files[0]
      );

      fetch("https://rifa.kelvinmarques.com.br/back-end/post.php", {
        method: "POST",
        body: fd,
      })
        .then((res) => {
          setSendingForm(false);
          if (res.status === 200) {
            (event.target as any).reset();
            refresh();
            setFormSuccess(true);
            return;
          }
          throw new Error();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [numberTaken, sendingForm]
  );

  const steamLogo = useMemo(
    () => (
      <img
        src="/img/steam.svg"
        style={{
          display: "inline-block",
          height: 20,
          marginLeft: 5,
          verticalAlign: "middle",
        }}
        alt="Steam"
      />
    ),
    []
  );

  return (
    <>
      {formOpen && (
        <section className={styles.formStage}>
          <form onSubmit={(event) => submitForm(event)}>
            {formSuccess ? (
              <>
                <p>Tudo certo! Agora é só aguardar o sorteio.</p>
                <p>Obrigado!</p>
                <div className={styles.buttons}>
                  <button type="button" onClick={() => setFormOpen(false)}>
                    Fechar
                  </button>
                </div>
              </>
            ) : (
              <>
                <p>
                  Para continuar, realize o pagamento do PIX no valor de<br /> R$ 20,00.
                </p>
                <p>
                  <a
                    href="https://nubank.com.br/pagar/se73/4nKWEf8sSF"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.button}
                  >
                    Fazer PIX de R$ 20,00
                  </a>
                </p>
                <br />
                <label htmlFor="numero">Número escolhido</label>
                <input
                  type="number"
                  name="numero"
                  max={60}
                  min={0}
                  onChange={(value) =>
                    setSelectedNumber(Number(value.currentTarget.value))
                  }
                  value={selectedNumber}
                  style={{ width: 40 }}
                />
                {numberTaken && (
                  <span className={styles.notAvailable}>
                    Número não disponível!
                  </span>
                )}
                <br />
                <label htmlFor="nome">Informe seu nome e sobrenome</label>
                <input type="text" name="nome" required />
                <br />
                <label htmlFor="telefone">Informe seu número de Whatsapp</label>
                <InputMask
                  mask="(99) 99999-9999"
                  name="telefone"
                  type="tel"
                  minLength={15}
                  required
                  maskChar=""
                />
                <br />
                <label htmlFor="comprovante">
                  Anexe o comprovante da transferência PIX
                </label>
                <input type="file" name="comprovante" required />
                <div className={styles.buttons}>
                  {sendingForm ? (
                    <>Aguarde...</>
                  ) : (
                    <>
                      <button type="button" onClick={() => setFormOpen(false)}>
                        Cancelar
                      </button>
                      <button type="submit" className={styles.buttonOk}>
                        Concluir
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </form>
        </section>
      )}

      <section className={styles.stage}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Curta suas férias de julho de{" "}
            <span className={styles.azulClaro}>Jogo Novo!</span>
          </h1>
          <p>
            Concorra a{" "}
            <span className={classNames(styles.numero, styles.azulClaro)}>
              R$ 250,00
            </span>{" "}
            em cédito na sua carteira {steamLogo} por apenas{" "}
            <span className={styles.numero}>R$ 20,00</span> cada número.
          </p>
          <ul className={styles.lista}>
            {numeros
              ? numeros.map((item) => (
                  <li
                    key={item.numero}
                    className={classNames({ [styles.taken]: item.taken })}
                    onClick={() => {
                      !item.taken && selectNumber(item.numero);
                    }}
                  >
                    {item.taken && item.nome && (
                      <span className={styles.tooltip}>{item.nome}</span>
                    )}
                    <span className={styles.number}>{item.numero}</span>
                  </li>
                ))
              : "por favor, aguarde"}
          </ul>
          <button className={styles.participar} onClick={() => scrollToInfo()}>
            Veja por que e como participar
            <br />⌄
          </button>
        </div>
      </section>
      <section
        className={styles.infos}
        ref={info}
      >
        <div>
          <p>
            Ao participar desta rifa, você ajudará uma senhora cujo o marido
            está afastado do trabalho por estar tratando intensas crises de
            ansiedade. Essa senhora possui um filho pequeno, que possui doenças
            e respiratórias, e faz faxinas para sustentar sua família e pagar
            pelo tratamento do marido, mas apenas com a renda dela, a família
            está passando por severas dificuldades financeiras.
          </p>
          <p>
            Além disso você concorrerá a R$ 250,00 em crédito na sua conta{" "}
            {steamLogo}.
          </p>
          <p>
            Esta rifa foi elaborada por amigos da beneficiária e não possui fins
            lucrativos. Todo o valor arrecadado será direcionado à beneficiária.
          </p>
          <h2>Como participar</h2>
          <ul>
            <li>
              Escolha e clique em um número que ainda esteja disponível
              (círculos dourados).
            </li>
            <li>
              Faça a transação no valor de R$ 20,00 e preencha o formulário.
            </li>
            <li>
              Pronto, é só aguardar para que todos os números sejam vendidos e o
              sorteio será realizado e gravado.
            </li>
            <li>
              Caso tenha algum problema,{" "}
              <a
                href="https://wa.me/5511984333790"
                target="_blank"
                rel="noreferrer"
              >
                clique aqui
              </a>{" "}
              e nos informe!
            </li>
          </ul>
          <p>
            Ao final da rifa, quando todos os números forem escolhidos, será
            realizado e gravado o sorteio de um dos números. O vencedor ganhará:
          </p>
          <ul>
            <li>
              R$ 250,00 disponibilizado como crédito na plataforma {steamLogo},
              ou
            </li>
            <li>
              R$ 250,00 em dinheiro por meio de transação bancária via PIX.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default HomePage;
